const StaffBankBranchesData = [
    {
    bbrCode: 10516,
    bbrBnkCode: 1039,
    bbrBranchName: "EAST CANTONMENTS",
    bbrShtDesc: "282001"
    },
    {
    bbrCode: 10517,
    bbrBnkCode: 1039,
    bbrBranchName: "TEMA MAIN",
    bbrShtDesc: "282002"
    },
    {
    bbrCode: 10518,
    bbrBnkCode: 1039,
    bbrBranchName: "OSU OXFORD",
    bbrShtDesc: "282003"
    },
    {
    bbrCode: 10519,
    bbrBnkCode: 1039,
    bbrBranchName: "LASHIBI",
    bbrShtDesc: "282004"
    },
    {
    bbrCode: 10520,
    bbrBnkCode: 1039,
    bbrBranchName: "WATSON HOUSE, OSU",
    bbrShtDesc: "282006"
    },
    {
    bbrCode: 10521,
    bbrBnkCode: 1039,
    bbrBranchName: "KANTAMANTO",
    bbrShtDesc: "282007"
    },
    {
    bbrCode: 10522,
    bbrBnkCode: 1039,
    bbrBranchName: "KANESHIE",
    bbrShtDesc: "282008"
    },
    {
    bbrCode: 10523,
    bbrBnkCode: 1039,
    bbrBranchName: "CASTLE ROAD",
    bbrShtDesc: "282009"
    },
    {
    bbrCode: 10524,
    bbrBnkCode: 1039,
    bbrBranchName: "MADINA",
    bbrShtDesc: "282010"
    },
    {
    bbrCode: 10525,
    bbrBnkCode: 1039,
    bbrBranchName: "FADAMA",
    bbrShtDesc: "282011"
    },
    {
    bbrCode: 10526,
    bbrBnkCode: 1039,
    bbrBranchName: "TAMALE",
    bbrShtDesc: "282012"
    },
    {
    bbrCode: 10527,
    bbrBnkCode: 1039,
    bbrBranchName: "ADUM",
    bbrShtDesc: "282013"
    },
    {
    bbrCode: 10528,
    bbrBnkCode: 1039,
    bbrBranchName: "SUAME",
    bbrShtDesc: "282014"
    },
    {
    bbrCode: 10529,
    bbrBnkCode: 1039,
    bbrBranchName: "TARKWA",
    bbrShtDesc: "282015"
    },
    {
    bbrCode: 10530,
    bbrBnkCode: 1039,
    bbrBranchName: "TAKORADI",
    bbrShtDesc: "282016"
    },
    {
    bbrCode: 10531,
    bbrBnkCode: 1039,
    bbrBranchName: "NORTH INDUSTRIAL AREA",
    bbrShtDesc: "282017"
    },
    {
    bbrCode: 10532,
    bbrBnkCode: 1039,
    bbrBranchName: "RING ROAD CENTRAL",
    bbrShtDesc: "282018"
    },
    {
    bbrCode: 10533,
    bbrBnkCode: 1039,
    bbrBranchName: "KANESHIE POST OFFICE",
    bbrShtDesc: "282019"
    },
    {
    bbrCode: 10534,
    bbrBnkCode: 1039,
    bbrBranchName: "ABEKA LAPAZ",
    bbrShtDesc: "282020"
    },
    {
    bbrCode: 10535,
    bbrBnkCode: 1039,
    bbrBranchName: "OKAISHIE",
    bbrShtDesc: "282021"
    },
    {
    bbrCode: 10536,
    bbrBnkCode: 1039,
    bbrBranchName: "ASHAIMAN",
    bbrShtDesc: "282022"
    },
    {
    bbrCode: 10537,
    bbrBnkCode: 1039,
    bbrBranchName: "TECHIMAN",
    bbrShtDesc: "282023"
    },
    {
    bbrCode: 10538,
    bbrBnkCode: 1039,
    bbrBranchName: "UPSA",
    bbrShtDesc: "282024"
    },
    {
    bbrCode: 10539,
    bbrBnkCode: 1039,
    bbrBranchName: "ACHIMOTA",
    bbrShtDesc: "282025"
    },
    {
    bbrCode: 10540,
    bbrBnkCode: 1039,
    bbrBranchName: "TEMA COMMUNITY 1",
    bbrShtDesc: "282026"
    },
    {
    bbrCode: 10541,
    bbrBnkCode: 1039,
    bbrBranchName: "NIMA",
    bbrShtDesc: "282027"
    },
    {
    bbrCode: 10542,
    bbrBnkCode: 1039,
    bbrBranchName: "AMAKOM",
    bbrShtDesc: "282028"
    },
    {
    bbrCode: 10543,
    bbrBnkCode: 1039,
    bbrBranchName: "TEMA INDUSTRIAL AREA",
    bbrShtDesc: "282029"
    },
    {
    bbrCode: 10544,
    bbrBnkCode: 1039,
    bbrBranchName: "SEFWI WIAWSO",
    bbrShtDesc: "282030"
    },
    {
    bbrCode: 10545,
    bbrBnkCode: 1039,
    bbrBranchName: "ENCHI",
    bbrShtDesc: "282031"
    },
    {
    bbrCode: 10546,
    bbrBnkCode: 1039,
    bbrBranchName: "IRIS",
    bbrShtDesc: "282032"
    },
    {
    bbrCode: 10547,
    bbrBnkCode: 1039,
    bbrBranchName: "KASOA",
    bbrShtDesc: "282033"
    },
    {
    bbrCode: 10548,
    bbrBnkCode: 1039,
    bbrBranchName: "SPINTEX",
    bbrShtDesc: "282034"
    },
    {
    bbrCode: 10549,
    bbrBnkCode: 1039,
    bbrBranchName: "HO",
    bbrShtDesc: "282035"
    },
    {
    bbrCode: 10550,
    bbrBnkCode: 1039,
    bbrBranchName: "NEWTOWN",
    bbrShtDesc: "282036"
    },
    {
    bbrCode: 10551,
    bbrBnkCode: 1039,
    bbrBranchName: "ALABAR",
    bbrShtDesc: "282037"
    },
    {
    bbrCode: 10552,
    bbrBnkCode: 1039,
    bbrBranchName: "HAATSO",
    bbrShtDesc: "282038"
    },
    {
    bbrCode: 10553,
    bbrBnkCode: 1039,
    bbrBranchName: "BOLGATANGA",
    bbrShtDesc: "282039"
    },
    {
    bbrCode: 10554,
    bbrBnkCode: 1039,
    bbrBranchName: "GACC",
    bbrShtDesc: "282040"
    },
    {
    bbrCode: 10555,
    bbrBnkCode: 1039,
    bbrBranchName: "WA",
    bbrShtDesc: "282041"
    },
    {
    bbrCode: 10556,
    bbrBnkCode: 1039,
    bbrBranchName: "KNUST",
    bbrShtDesc: "282042"
    },
    {
    bbrCode: 10557,
    bbrBnkCode: 1039,
    bbrBranchName: "LEGON",
    bbrShtDesc: "282043"
    },
    {
    bbrCode: 10558,
    bbrBnkCode: 1039,
    bbrBranchName: "ADJIRIGANOR",
    bbrShtDesc: "282044"
    },
    {
    bbrCode: 10559,
    bbrBnkCode: 1039,
    bbrBranchName: "KEJETIA",
    bbrShtDesc: "282045"
    },
    {
    bbrCode: 10560,
    bbrBnkCode: 1039,
    bbrBranchName: "OCTAGON",
    bbrShtDesc: "282046"
    },
    {
    bbrCode: 10561,
    bbrBnkCode: 1039,
    bbrBranchName: "TAKORADI MARKET CIRCLE",
    bbrShtDesc: "282047"
    },
    {
    bbrCode: 10562,
    bbrBnkCode: 1039,
    bbrBranchName: "KOFORIDUA",
    bbrShtDesc: "282048"
    },
    {
    bbrCode: 10563,
    bbrBnkCode: 1039,
    bbrBranchName: "TUDU",
    bbrShtDesc: "282049"
    },
    {
    bbrCode: 10564,
    bbrBnkCode: 1039,
    bbrBranchName: "ODORKOR",
    bbrShtDesc: "282050"
    },
    {
    bbrCode: 10565,
    bbrBnkCode: 1039,
    bbrBranchName: "DARKUMAN",
    bbrShtDesc: "282051"
    },
    {
    bbrCode: 10566,
    bbrBnkCode: 1039,
    bbrBranchName: "AHODWO",
    bbrShtDesc: "282052"
    },
    {
    bbrCode: 10567,
    bbrBnkCode: 1039,
    bbrBranchName: "SEFWI DWENASE",
    bbrShtDesc: "282053"
    },
    {
    bbrCode: 10568,
    bbrBnkCode: 1039,
    bbrBranchName: "AGBOGBLOSHIE",
    bbrShtDesc: "282054"
    },
    {
    bbrCode: 10569,
    bbrBnkCode: 1039,
    bbrBranchName: "SOUTH INDUSTRIAL AREA",
    bbrShtDesc: "282055"
    },
    {
    bbrCode: 10570,
    bbrBnkCode: 1039,
    bbrBranchName: "AIRPORT",
    bbrShtDesc: "282056"
    },
    {
    bbrCode: 10571,
    bbrBnkCode: 1039,
    bbrBranchName: "KUMASI ASAFO",
    bbrShtDesc: "282057"
    }
    ];

export default StaffBankBranchesData;
